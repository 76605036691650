import { initializeApp as fbInitializeApp, getApps } from 'firebase/app';
import { isEmpty } from 'lodash-es';

import type { FirebaseOptions } from 'firebase/app';

const isInitialized = () => !isEmpty(getApps());

const initializeApp = (options: FirebaseOptions) =>
  !isInitialized() && fbInitializeApp(options);

export { initializeApp };
